<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="carouselWrap">
    <img
      v-if="element.style.carouselPics.length"
      :src="element.style.carouselPics[0].file_down_path"
      class="carouselImage"
    />
    <i class="iconfont icon-lunbotu" v-else></i>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.carouselWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  .carouselImage {
    width: 100%;
    height: 100%;
  }
  .iconfont {
    font-size: 40px;
  }
}
</style>
